<template>
  <CardToolbox>
    <sdPageHeader :title="locale.page.senderList.title">
      <template #subTitle>
        <span class="title-counter">{{ count }} afzender{{ count > 0 && count < 2 ? '' : 's' }}</span>
        <AutoCompleteStyled style="width:100%" placeholder="Zoeken op naam...">
          <a-input type="input" v-model:value="search">
            <template #suffix>
              <sdFeatherIcons type="search"/>
            </template>
          </a-input>
        </AutoCompleteStyled>
      </template>
      <template #buttons>
        <SenderAdd v-if="$can('create', 'sender')"/>
      </template>
    </sdPageHeader>
  </CardToolbox>

  <Main>
    <a-row :gutter="15">
      <a-col :md="24">
        <SenderListTable/>
      </a-col>
    </a-row>
  </Main>
</template>
<script>
import SenderListTable from './component/SenderTable';
import {computed, ref, defineComponent, onMounted, watch} from 'vue';
import {useStore} from 'vuex';
import {Main, CardToolbox, AutoCompleteStyled} from '../styled';
import SenderAdd from "./SenderAdd";
import locale from "@/static/locale/nl.json";

const SenderList = defineComponent({
  name: 'SenderList',
  components: {SenderAdd, Main, CardToolbox, SenderListTable, AutoCompleteStyled},
  setup() {
    const {state, dispatch} = useStore();
    const searchData = computed(() => state.headerSearchData);
    const count = computed(() => state.sender.count);
    const selectedRowKeys = ref(0);
    const selectedRows = ref(0);
    const search = ref(null);
    let debounce = null;

    watch(search, (query) => {
      clearTimeout(debounce)
      debounce = setTimeout(async () => {
        const searchValues = {
          q: query,
          pageSize: state.sender.pageSize,
          page: 1,
        };
        await dispatch('setCurrentPageSenders', 1);
        await dispatch('getSenders', searchValues);
      }, 600);
    });

    onMounted(() => {
      window.scrollTo(0, 0);
      dispatch('getSenders', {page: state.sender.currentPage, pageSize: state.sender.pageSize});
    });

    return {
      searchData,
      selectedRowKeys,
      selectedRows,
      count,
      locale,
      search,
    };
  },
});

export default SenderList;
</script>
