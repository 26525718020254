<template>
  <sdDrawer
      ref="SenderAddRef"
      :form="form"
      title="Afzender aanmaken"
      type="submit"
      btnText="Toevoegen"
      submitBtnText="Opslaan"
  >
    <FormValidationWrap>
      <a-form ref="formRef" :model="form" :rules="rules" layout="vertical" @finish="CreateSender">
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="Naam" name="name">
              <a-input v-model:value="form.name" placeholder=""/>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="E-mailadres" name="email">
              <a-input v-model:value="form.email" placeholder=""/>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="Telefoonnummer" name="phoneNumber">
              <a-input type="number" v-model:value="form.phoneNumber" placeholder=""
                       onkeydown="if(event.key==='.'||event.key===','){event.preventDefault();}"
                       onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"/>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="Afzender naam" name="senderName">
              <a-input v-model:value="form.senderName" placeholder=""/>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="Afzender e-mailadres" name="senderEmail">
              <a-input v-model:value="form.senderEmail" placeholder=""/>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item label="Postcode" name="postalCode">
              <a-input v-model:value="form.postalCode" placeholder=""/>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="Huisnummer" name="houseNumber">
              <a-input type="number" v-model:value="form.houseNumber" placeholder=""
                       onkeydown="if(event.key==='.'||event.key===','){event.preventDefault();}"
                       onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"/>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="Toevoeging" name="houseNumberAddition">
              <a-input v-model:value="form.houseNumberAddition" placeholder=""/>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="10">
            <a-form-item label="Straat" name="postalCode">
              <a-input v-model:value="form.street" placeholder=""/>
            </a-form-item>
          </a-col>
          <a-col :span="7">
            <a-form-item label="Plaats" name="city">
              <a-input v-model:value="form.city" placeholder=""/>
            </a-form-item>
          </a-col>
          <a-col :span="7">
            <a-form-item label="Land" name="country">
              <a-select v-model:value="form.country" size="large" name="country" class="sDash_fullwidth-select"
                        :allowClear="true">
                <a-select-option v-for="(item,index) in countryOptions" :key="index" :value="item.value">
                  {{ item.key }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item label="Primaire kleur" name="primaryColor">
              <a-input
                  :style="{ height: '55px' }"
                  name="color"
                  type="color"
                  v-model:value="form.primaryColor"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="Secundaire kleur" name="secondaryColor">
              <a-input
                  :style="{ height: '55px' }"
                  name="color"
                  type="color"
                  v-model:value="form.secondaryColor"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <div
            :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
          }"
        >
          <a-button size="large" class="btn-signin" type="primary" html-type="submit" :loading="loadingCreateSender"
                    :disabled="loadingCreateSender"> Opslaan
          </a-button>
        </div>
      </a-form>
    </FormValidationWrap>
  </sdDrawer>
</template>
<script>
import {computed, defineComponent, reactive, ref} from 'vue';
import {FormValidationWrap} from "../styled";
import {useStore} from "vuex";
import {countryOptions} from "../../utility/enums";
import {phoneNumberValidator, postalCodeValidator} from "../../utility/validators";

const UserAdd = defineComponent({
  name: 'SenderAdd',
  components: {
    FormValidationWrap,
  },
  setup() {
    const SenderAddRef = ref()
    const formRef = ref();
    const {state, dispatch} = useStore();
    const loadingCreateSender = computed(() => state.sender.loadingCreateSender);
    const form = reactive({
      city: null,
      country: null,
      email: null,
      houseNumber: null,
      houseNumberAddition: null,
      name: null,
      phoneNumber: null,
      postalCode: null,
      primaryColor: null,
      secondaryColor: null,
      senderEmail: null,
      senderName: null,
      street: null,
    });
    const rules = {
      name: [
        {
          required: true,
          message: 'Dit veld is verplicht',
        },
      ],
      email: [
        {
          required: true,
          type: "email",
          message: "Vul een valide e-mailadres in",
        },
      ],
      phoneNumber: [
        {
          required: true,
          message: 'Dit veld is verplicht',
        },
        {
          validator: phoneNumberValidator,
        },
      ],
      senderEmail: [
        {
          required: true,
          type: "email",
          message: "Vul een valide e-mailadres in",
        },
      ],
      senderName: [
        {
          required: true,
          message: 'Dit veld is verplicht',
        },
      ],
      primaryColor: [
        {
          required: true,
          message: 'Dit veld is verplicht',
        },
      ],
      secondaryColor: [
        {
          required: true,
          message: 'Dit veld is verplicht',
        },
      ],
      houseNumber: [
        {
          required: true,
          message: 'Dit veld is verplicht',
        },
      ],
      postalCode: [
        {
          required: true,
          message: 'Dit veld is verplicht',
        },
        {
          validator: postalCodeValidator,
        },
      ],
      city: [
        {
          required: true,
          message: 'Dit veld is verplicht',
        },
      ],
      country: [
        {
          required: true,
          message: 'Dit veld is verplicht',
        },
      ],
    };
    const closeDrawer = () => {
      SenderAddRef.value.onClose();
      formRef.value.resetFields();
      dispatch('getSenders')
    }
    const CreateSender = async () => {
      await dispatch('createSender', {value: form, close: closeDrawer});
      await dispatch('getSenders', {page: 1, pageSize: state.sender.pageSize});
    };
    return {
      form,
      rules,
      CreateSender,
      countryOptions,
      loadingCreateSender,
      SenderAddRef,
      formRef,
    };
  },
});

export default UserAdd;
</script>
