<template>
  <TableStyleWrapper>
    <TableWrapper class="table-responsive">
      <a-table
          :loading="loadingSenders"
          :dataSource="senderTableData"
          :columns="senderTableColumns"
          :pagination="{
          defaultPageSize: 10,
          total: count,
          current: currentPage,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${count} items`,
        }"
          @change="paginationChange"
      />
    </TableWrapper>
  </TableStyleWrapper>
</template>
<script>
import {TableStyleWrapper} from '../style';
import {TableWrapper} from '../../styled';
import {computed, defineComponent} from 'vue';
import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";
import moment from "moment";
import {useAbility} from "@casl/vue";

const senderTableColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Naam',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Afzender',
    dataIndex: 'sender',
    key: 'sender',
  },
  {
    title: 'Email geverifieerd',
    dataIndex: 'senderEmailVerified',
    key: 'senderEmailVerified',
  },
  {
    title: 'Aangemaakt op',
    dataIndex: 'createTimestamp',
    key: 'createTimestamp',
  },
  {
    title: 'Actions',
    dataIndex: 'action',
    key: 'action',
    width: '90px',
  },
];
const SenderListTable = defineComponent({
  name: 'SenderListTable',
  components: {TableStyleWrapper, TableWrapper},
  setup() {
    const router = useRouter();
    const {state, dispatch} = useStore();
    const {matched} = useRoute();
    const {path} = matched[1];
    const senders = computed(() => state.sender.senders);
    const loadingSenders = computed(() => state.sender.loadingSenders);
    const currentPage = computed(() => state.sender.currentPage);
    const count = computed(() => state.sender.count);
    const { can } = useAbility();
    const handleDelete = async (id) => {
      await dispatch('deleteSender', id);
      await dispatch('getSenders', {page: state.sender.currentPage, pageSize: state.sender.pageSize});
    };
    const handleEdit = (id) => {
      router.push(`${path}/${id}`);
    };
    const senderTableData = computed(() =>
        senders.value.map((sender) => {
          const {id, name, senderName, senderEmailVerified, imageFileURL, createTimestamp} = sender;

          return {
            key: id,
            id: id,
            name: (
                <div class="user-info">
                  <figure>
                    {imageFileURL && imageFileURL !== '' ? (
                        <img style={{width: '40px'}} src={`${imageFileURL}`} alt=""/>
                    ) : (
                        <img style={{width: '40px'}} src={require('@/static/img/users/1.png')} alt=""/>
                    )}
                  </figure>
                  <figcaption>
                    <sdHeading class="user-name" as="h6">
                      {name}
                    </sdHeading>
                  </figcaption>
                </div>
            ),
            sender: <span>{senderName}</span>,
            senderEmailVerified: <span>{senderEmailVerified ? ('Geverifieerd') : ('Niet geverifieerd')}</span>,
            createTimestamp: <span>{moment(createTimestamp).format('DD-MM-YYYY HH:mm:ss')}</span>,
            action: (
                <div className="table-actions">
                  {can('view', 'sender') ? (
                      <sdButton onClick={() => handleEdit(id)} className="btn-icon" to="#" type="default" shape="circle">
                        <sdFeatherIcons type="edit" size="16"/>
                      </sdButton>
                  ) : ('')}
                  {can('delete', 'sender') ? (
                      <a-popconfirm
                          title="Weet je het zeker? U kunt dit niet meer terugdraaien!"
                          ok-text="Ja"
                          cancel-text="Nee"
                          placement="leftTop"
                          onConfirm={() => handleDelete(id)}
                      >
                        <sdButton className="btn-icon" type="default" to="#" shape="circle">
                          <sdFeatherIcons type="trash-2" size="16"/>
                        </sdButton>
                      </a-popconfirm>
                  ) : ('')}
                </div>
            ),
          };
        }),
    );

    const rowSelection = {
      getCheckboxProps: (record) => ({
        disabled: record.name === 'Disabled sender', // Column configuration not to be checked
        name: record.name,
      }),
    };

    const paginationChange = async (event) => {
      await dispatch('setCurrentPageSenders', event.current);
      await dispatch('getSenders', {page: event.current, pageSize: state.sender.pageSize});
    }

    return {senderTableColumns, senderTableData, rowSelection, loadingSenders, paginationChange, count, currentPage};
  },
});

export default SenderListTable;
</script>
